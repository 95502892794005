import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

@Component({
	selector: 'app-auth-callback',
	templateUrl: './auth-callback.component.html',
	styleUrls: ['./auth-callback.component.scss']
})
export class AuthCallbackComponent implements OnInit {

	constructor(private route: ActivatedRoute, private router: Router) {
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(params => {
			const code = params['code'];
			const state = params['state'];
			if (code) {
				Auth.federatedSignIn({customProvider: 'azured' as CognitoHostedUIIdentityProvider, customState: state}).then(
					data => {
					}, error => {
						console.error(error);
					});
				//{provider: 'azuread' as CognitoHostedUIIdentityProvider, token: code}
			}
		});
	}

}
