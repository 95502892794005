import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	constructor(private router: Router,
	            private location: Location,
	            private userService: UserService) {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (localStorage.getItem('jwtToken')) {
			req = req.clone({
				headers: req.headers.append('Authorization', `Bearer ${localStorage.getItem('jwtToken')}`)
			});
		}

		return next.handle(req);
	}

}
