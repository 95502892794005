import { BaseServiceClass } from './base-service';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtUser, UpdateUser, User, ForgotPassword, ActivateUser, Password } from '@app/model';
import { Router } from '@angular/router';
import { Auth } from '@aws-amplify/auth';
import { from, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { map, switchMap, tap } from "rxjs/operators";
import { ChangePassword } from '../model/request/change-password';
import { ToastService } from "./toast.service";

const API_URL = environment.apiUrl;

@Injectable({
	providedIn: 'root'
})
export class UserService extends BaseServiceClass<any> {

	public tempUsername: string;

	public cognitoUser: any;

	public currentUser: User;
	public jwtUser: JwtUser | undefined;

	public jwtToken: string;

	public returnUrl: string = '';

	constructor(private http: HttpClient,
				private router: Router,
				private toastService: ToastService) {
		super(http, '/api/v1/users');
	}

	updateCognitoCurrentUser(useCache: boolean = true): Observable<void> {
		if(this.currentUser && this.currentUser.mfaEnabled && useCache) {
			return from([]);
		}

		return from(Auth.currentAuthenticatedUser()).pipe(
			switchMap(cognitoUser => {
				this.cognitoUser = cognitoUser;
				this.jwtUser = new JwtUser().deserialize(localStorage.getItem('jwtToken'));
				return this.getCurrentUser();
			}),
			map(currentUser => {
				this.currentUser = currentUser;
				from([]);
			})
		);
	}

	public activate(activateUser: ActivateUser, token: string): Observable<any> {
		let headers = new HttpHeaders({'token': token});
		return this.http.post(`${this.entity_url}/activate`, activateUser, { headers: headers });
	}

	public putPassword(): Observable<any> {
		return this.http.get('');
	}

	public getCurrentUser(): Observable<any> {
		return this.http.get<any>(`${this.entity_url}/current`).pipe(
			tap((user: User) => {
				if(user.status === 'INACTIVE') {
					this.toastService.showDanger('Usuário inativo. Entre em contato com o suporte.');
					this.logout();
				}
			})
		);
	}

	public put(obj: UpdateUser, id: any): Observable<any> {
		return this.httpService.put(`${this.entity_url}/${id}`, obj);
	}

	public updateStatusToActive(): Observable<any> {
		return this.httpService.patch(`${this.entity_url}/current/status/ACTIVE`, null);
	}

	public verify(token: string): Observable<any> {
		let headers = new HttpHeaders({'token': token});
		return this.http.post(`${this.entity_url}/email/verify`, {}, { headers: headers });
	}

	public changePassword(changePasswordRequest: ChangePassword): Observable<any> {
		return this.http.patch(`${this.entity_url}/current/password`, changePasswordRequest);
	}

	public postForgotPassword(forgotPassword: ForgotPassword): Observable<any> {
		return this.http.post(API_URL + '/api/v1/auth/forgotPassword', forgotPassword);
	}

	public putAuthPassword(password: Password, token: string): Observable<any> {
		let headers = new HttpHeaders({'token': token});
		return this.http.put(API_URL + '/api/v1/auth/password', password, { headers: headers });
	}

	public logout(redirectUrl?: string): void {
		this.jwtUser = undefined;
		this.currentUser = new User();
		localStorage.clear();

		if(redirectUrl) {
			localStorage.setItem('logoutRedirect', redirectUrl);
		}

		Auth.signOut({ global: true }).then(
			data => {
				this.router.navigate(['/']);
			}, error => {
				this.router.navigate(['/']);
				console.error(error);
			}
		);
	}

	public signUp(user: {name: string, email: string, password: string}): Observable<any> {
		return this.http.post(`${this.entity_url}/signup`, user);
	}

	public getCurrentUserPermissions(): Observable<any> {
		return this.http.get(`${this.entity_url}/current/roles`);
	}

	public validateRecaptcha(token: string): Observable<any> {
		return this.http.post(API_URL + '/api/v1/auth/validateRecaptcha', { recaptchaResponse: token });
	}

	public enableMfa(): Observable<any> {
		return this.http.patch(`${this.entity_url}/current/mfa/enable`, null);
	}

	public disableMfa(password: string): Observable<any> {
		return this.http.patch(`${this.entity_url}/current/mfa/disable`, { password: password });
	}

}
