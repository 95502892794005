import { Component, Inject, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Auth } from '@aws-amplify/auth';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { OrganizationService, SystemMessages, ToastService, UserService } from '@app/service';
import { AwsUserInfo, IdPermissions, JwtUser, SystemErrors } from '@app/model';
import { environment } from '../../../environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { MfaDialogComponent } from "./mfa/mfa-dialog.component";

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	public username: string = '';
	public password: string = '';
	public newPassword: string = '';

	public loginForm: FormGroup;
	public passwordRecoveryForm: FormGroup;
	public isSubmittingForm = false;
	public submittedLogin = false;

	public accessToken: string = '';
	public redirectApp: string;
	public redirectUrl: string;

	public showLoginForm: boolean = false;
	public currentState: string = LoginStates.LOGIN;

	public loginStates = LoginStates;

	public showPassword: boolean = false;
	public passwordButtonPositionClass: string = '';

	public enableSignUp: boolean = environment.features.enableSignUp;
	public enableAzureSignUp: boolean = environment.features.enableAzureSignUp;

	public recaptchaId: string = environment.recaptchaId;
	public recaptchaToken: string = '';
	public validRecaptcha: boolean = !environment.features.recaptchaEnabled;

	public recaptchaEnabled = environment.features.recaptchaEnabled;

	constructor(private formBuilder: FormBuilder,
				private activatedRoute: ActivatedRoute,
				private router: Router,
				private toastService: ToastService,
				public userService: UserService,
				public organizationService: OrganizationService,
				public ngxPermissionsService: NgxPermissionsService,
				private modalService: NgbModal,
				@Inject(DOCUMENT) private document: Document) {

		this.loginForm = this.formBuilder.group({
			username: ['', Validators.required],
			password: ['', Validators.required],
			recaptchaReactive: [!environment.features.recaptchaEnabled, [Validators.requiredTrue]]
		});
		this.passwordRecoveryForm = this.formBuilder.group({
			username: ['', [Validators.required]],
		});

		this.redirectApp = this.activatedRoute.snapshot.queryParams['redirectApp'] ? this.activatedRoute.snapshot.queryParams['redirectApp'] : 'id';
		this.redirectUrl = this.activatedRoute.snapshot.queryParams['redirectUrl'] ? this.activatedRoute.snapshot.queryParams['redirectUrl'] : undefined;
	}

	public get loginFormCtrl() {
		return this.loginForm.controls;
	}

	ngOnInit(): void {
		this.getCurrentSession();
	}

	public getCurrentSession(): void {
		if (!localStorage.getItem('jwtToken')) {
			this.showLoginForm = true;
			return;
		}

		Auth.currentAuthenticatedUser().then(
			cognitoUser => {
				this.userService.cognitoUser = cognitoUser;
				this.userService.jwtUser = new JwtUser().deserialize(localStorage.getItem('jwtToken'));

				this.userService.getCurrentUser().subscribe(
					data => {
						this.userService.currentUser = data;
						if (!this.userService.currentUser.mfaEnabled) {
							this.validateSignIn(cognitoUser);
						} else {
							this.validateCurrentUser();
						}
					}, error => {
						this.showLoginForm = true;
					});
			}, error => {
				this.showLoginForm = true;
			});
	}

	setAdminPermission() {
		if (this.userService.currentUser.organizations.find(org => {
			if (!this.organizationService.currentOrganization.id) {
				this.organizationService.currentOrganization = org;
			}

			return org.admin;
		})) {
			this.ngxPermissionsService.addPermission(IdPermissions.ALL_PERMISSIONS);
		}
	}

	submitLogin() {
		this.submittedLogin = true;

		if (this.loginForm.invalid) {
			return;
		}
		this.isSubmittingForm = true;

		Auth.signIn(this.loginFormCtrl.username.value, this.loginFormCtrl.password.value).then(
			cognitoUser => {
				if (!cognitoUser.signInUserSession && cognitoUser.challengeName == 'SOFTWARE_TOKEN_MFA') {
					this.userService.tempUsername = this.loginFormCtrl.username.value;
					this.userService.cognitoUser = cognitoUser;
					this.openMfaDialog(false, 'APP', cognitoUser.challengeName);
					return;
				}

				this.userService.cognitoUser = cognitoUser;
				this.userService.tempUsername = cognitoUser.username;
				if (cognitoUser.challengeName == 'PASSWORD_VERIFIER' || cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
					this.currentState = LoginStates.CHANGE_PASSWORD;
					return;
				}

				this.userService.jwtToken = cognitoUser.signInUserSession.accessToken.jwtToken;
				localStorage.setItem('jwtToken', this.userService.jwtToken);

				this.userService.getCurrentUser().subscribe(
					data => {
						localStorage.removeItem('jwtToken');
						this.userService.currentUser = data;
						this.validateSignIn(cognitoUser);
					}, error => {
						this.toastService.showDanger(SystemErrors.UNEXPECTED_ERROR);
						this.isSubmittingForm = false;
					});
			}
		).catch(error => {
			console.error(error);
			this.toastService.showDanger('Usuário ou senha inválidos.');
			this.isSubmittingForm = false;
		});
	}

	changeShowPasswordButtonPosition() {
		if (this.loginForm.get('password')?.untouched) return;
		this.passwordButtonPositionClass = this.loginForm.get('password')?.invalid ? 'mr-4' : '';
	}

	public loginWithAzure(): void {
		Auth.federatedSignIn({customProvider: 'azured'}).then(
			data => {
			},
			error => {
				console.error(error)
			}
		);
	}

	public validateRecaptcha(event: any): void {
		this.recaptchaToken = event;

		this.userService.validateRecaptcha(this.recaptchaToken).subscribe(
			data => {
				this.validRecaptcha = data.success;
				this.loginForm.get('recaptchaReactive')?.setValue(data.success);
			}, error => {
				this.validRecaptcha = false;
				this.loginForm.get('recaptchaReactive')?.setValue(null);
				// this.toastService.showDanger(SystemMessages.INVALID_RECAPTCHA);
			});
	}

	public validateRecpatchaError(event: any): void {
		// this.toastService.showDanger(SystemMessages.INVALID_RECAPTCHA);
	}

	private validateCurrentUser(): void {
		if (this.userService.currentUser.status == 'FORCE_PASSWORD_CHANGE') {
			this.addPermissionsAndRedirect("profile/security");
			return;
		}

		if(this.userService.currentUser.status == 'INACTIVE') {
			this.toastService.showDanger('Usuário inativo. Entre em contato com o suporte.');
			this.userService.logout();
			return;
		}

		if(this.userService.returnUrl == '/profile') {
			this.addPermissionsAndRedirect();
			return;
		}

		if (this.userService.currentUser.organizations.length === 0) {
			this.redirectApp = environment.apps['admin'];
			this.redirectUser();
			return;
		}

		if (localStorage.getItem('logoutRedirect')) {
			let logoutRedirect = localStorage.getItem('logoutRedirect');
			localStorage.removeItem('logoutRedirect');
			this.document.location.href = environment.apps[logoutRedirect as string];
			return;
		}

		if (this.userService.returnUrl == '') {
			if(!this.redirectApp || this.redirectApp == 'id') {
				this.redirectApp = environment.apps['quality'];
			}
			this.redirectUser();
			return;
		}

		this.addPermissionsAndRedirect();
	}

	private addPermissionsAndRedirect(url?: string): void {
		this.addPermissions();
		this.router.navigate([url ? url : this.userService.returnUrl]);
		this.userService.returnUrl = '';
	}

	private addPermissions(): void {
		let trainingPermission = false;

		if (this.userService.currentUser.roles) {
			this.userService.currentUser.roles.some(role => {
				role.permissions.some((permission: any) => {
					if (permission.name == IdPermissions.TRAINING_ACCESS) {
						trainingPermission = true;
						this.organizationService.currentOrganization = role.organization;
					}

					return trainingPermission;
				});

				return trainingPermission;
			});
		}

		if (trainingPermission) {
			this.ngxPermissionsService.addPermission(IdPermissions.TRAINING_ACCESS);
		}

		this.setAdminPermission();
	}

	public validateLoginChallenges(): void {
		if (this.userService.cognitoUser.challengeName == 'PASSWORD_VERIFIER' || this.userService.cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
			this.currentState = LoginStates.CHANGE_PASSWORD;
			return;
		}
	}

	private openMfaDialog(canRegister: boolean, mfaType: string, challengeName: string | null): void {
		const modalRef = this.modalService.open(MfaDialogComponent, {backdrop: 'static', keyboard: false});
		modalRef.componentInstance.canRegister = canRegister;
		modalRef.componentInstance.mfaType = mfaType;
		modalRef.componentInstance.challengeName = challengeName;

		modalRef.result.then(data => {
			if (data) {
				this.validateCurrentUser();
			}
		});
	}

	private redirectUser(): void {
		window.location.href = this.redirectApp + '/login?token=' + localStorage.getItem('jwtToken') + '&returnUrl=' + this.redirectUrl;
	}

	private validateSignIn(cognitoUser: any): void {
		this.userService.cognitoUser = cognitoUser;
		if (cognitoUser.attributes) {
			this.userService.tempUsername = cognitoUser.attributes.email
		} else {
			this.userService.tempUsername = this.loginForm.get('username')?.value;
		}

		// Não deve mais cair aqui
		if (cognitoUser.challengeName == 'PASSWORD_VERIFIER' || cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
			this.currentState = LoginStates.CHANGE_PASSWORD;
			return;
		}

		// Se o usuário não tiver um MFA registrado, ele deve registrar um
		if (!this.userService.currentUser.mfaRegistered) {
			this.openMfaDialog(true, 'APP', null);
			return;
		}

		// Setando token antes de redirecionar, ou ficar no ID
		localStorage.setItem('jwtToken', this.userService.jwtToken);

		if (this.redirectApp != 'id') {
			this.redirectUser();
			return;
		}

		this.userService.jwtUser = new JwtUser().deserialize(localStorage.getItem('jwtToken'));

		this.validateCurrentUser();
	}

}

export enum LoginStates {
	LOGIN = 'login',
	CHANGE_PASSWORD = 'changePassword'
}
